import styled from "styled-components"

import { media } from "./../../common/MediaQueries"
import { wave } from "./../../common/Keyframes"
import { getNumbersAnimation } from "./../../common/mixins"

const NumbersHeroStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media ${media.tablet} {
    width: 838px;
    height: 516px;
  }
`

const NumbersHeroItemStyled = styled.div`
  position: relative;

  @media ${media.tablet} {
    figure {
      @supports not (-ms-high-contrast: none) {
        animation-name: ${wave};
        animation-iteration-count: infinite;
        animation-delay: 1s;
      }
    }
  }

  &:nth-child(1) {
    z-index: 2;
    align-self: flex-end;

    min-width: 90px;
    height: 124px;
    @media ${media.phone} {
      min-width: 142px;
      height: 196px;
    }
    @media ${media.tablet} {
      min-width: 254px;
      height: 348px;
    }
  }
  &:nth-child(2) {
    align-self: flex-start;
    z-index: 1;
    min-width: 72px;
    height: 80px;
    figure {
      left: -67%;
      // opacity: 0.8;
    }
    @media ${media.phone} {
      min-width: 114px;
      height: 128px;
    }
    @media ${media.tablet} {
      min-width: 200px;
      height: 226px;
    }
  }
  &:nth-child(3) {
    align-self: center;
    min-width: 90px;
    height: 115px;
    figure {
      left: -58%;
      top: 17%;
      // opacity: 0.6;
    }
    @media ${media.phone} {
      min-width: 142px;
      height: 182px;
    }
    @media ${media.tablet} {
      min-width: 252px;
      height: 322px;
    }
  }
  &:nth-child(4) {
    align-self: flex-start;

    min-width: 79px;
    height: 71px;
    figure {
      left: -78%;
      top: 24%;
      opacity: 0.5;
    }
    @media ${media.phone} {
      min-width: 125px;
      height: 113px;
    }
    @media ${media.tablet} {
      min-width: 220px;
      height: 200px;
    }
  }
  &:nth-child(5) {
    align-self: flex-end;

    min-width: 60px;
    height: 69px;
    figure {
      left: -146%;
      top: -26%;
      opacity: 0.4;
    }
    @media ${media.phone} {
      min-width: 95px;
      height: 109px;
    }
    @media ${media.tablet} {
      min-width: 168px;
      height: 193px;
    }
  }
  @media ${media.tablet} {
    ${getNumbersAnimation(6)};
  }
`

const NumbersHeroFigureStyled = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;
`

export { NumbersHeroStyled, NumbersHeroItemStyled, NumbersHeroFigureStyled }
