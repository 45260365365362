import React from "react"

import {
  HeroStyled,
  HeroInnerStyled,
  HeroContentStyled,
  HeroHeadingStyled,
  HeroSubheadingStyled,
  HeroDescriptionStyled,
  HeroActionStyled,
  HeroMediaStyled,
  HeroInnerContainerStyled,
  HeroAdditionalStyled,
} from "./styles"
import NumbersHero from "./../NumbersHero"
import IconButton from "./../IconButton"

interface IProps {
  isAlt?: boolean
  heading: React.ReactNode
  subheading?: React.ReactNode
  description?: React.ReactNode
  linkUrl?: string
  linkText?: string
  additional?: React.ReactNode
  images?: []
}

const Hero: React.FC<IProps> = ({
  heading,
  subheading,
  description,
  linkUrl,
  linkText,
  images,
  additional,
  isAlt,
}) => {
  return (
    <HeroStyled {...{ isAlt }}>
      <HeroInnerStyled>
        <HeroInnerContainerStyled>
          <HeroContentStyled>
            <HeroHeadingStyled>{heading}</HeroHeadingStyled>
            {subheading && (
              <HeroSubheadingStyled>{subheading}</HeroSubheadingStyled>
            )}
            {description && (
              <HeroDescriptionStyled>{description}</HeroDescriptionStyled>
            )}
            {linkUrl && (
              <HeroActionStyled>
                <IconButton href={linkUrl} text={linkText} isToDownload />
              </HeroActionStyled>
            )}
            {additional && (
              <HeroAdditionalStyled>{additional}</HeroAdditionalStyled>
            )}
          </HeroContentStyled>
          <HeroMediaStyled>
            <NumbersHero items={images} />
          </HeroMediaStyled>
        </HeroInnerContainerStyled>
      </HeroInnerStyled>
    </HeroStyled>
  )
}

export default Hero
