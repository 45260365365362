import React from "react"

import {
  NumbersHeroStyled,
  NumbersHeroItemStyled,
  NumbersHeroFigureStyled,
} from "./styles"
import Img from "./../Image"

interface IProps {
  items: []
}

const NumbersHero: React.FC<IProps> = ({ items }) => {
  return (
    <NumbersHeroStyled>
      {items.map(el => (
        <NumbersHeroItemStyled key={el.node.id}>
          <NumbersHeroFigureStyled>
            <Img fluid={el.node.fluid} />
          </NumbersHeroFigureStyled>
        </NumbersHeroItemStyled>
      ))}
    </NumbersHeroStyled>
  )
}

export default NumbersHero
