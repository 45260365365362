import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import { IntroStyled } from "../components/Intro"
import Hero from "../components/Hero"
import HubSpot from "./../components/HubSpot"
import { Heading1, Heading4 } from "./../components/Typography"
import theme from "./../themes/theme"
import { PORTAL_ID, DOWNLOAD_FORM_ID } from "./../constants/hubspot"

const DownloadPage: React.FC<IProps> = ({ data }) => {
  const initializeHubspotForm = () => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: PORTAL_ID,
        formId: DOWNLOAD_FORM_ID,
        target: "#download-form",
      })
    } else {
      setTimeout(initializeHubspotForm, 500)
    }
  }

  useEffect(() => {
    initializeHubspotForm()
  }, [])

  return (
    <Layout withoutFooter isDarkBg>
      <SEO
        title="Download report - Brightest Brands"
        description="The only brand index that is solely focused on business-to-business brands. From commercial real estate to financial services to technology – we deep dive into 200 companies across 18 sectors, accessing them across 10 key criteria."
      />
      <IntroStyled>
        <Hero
          isAlt
          heading={
            <Heading1 as="h1" color="brand" fontWeight={theme.fontWeight.black}>
              Download the report
            </Heading1>
          }
          subheading={
            <Heading4 fontWeight={theme.fontWeight.black} color="white">
              For further insights and sector deep dives, please complete the
              form to download full report:
            </Heading4>
          }
          additional={<HubSpot id="download-form" isDownload />}
          images={data.heroNumbersImg.edges}
        />
      </IntroStyled>
    </Layout>
  )
}

export default DownloadPage

export const pageQuery = graphql`
  query {
    heroNumbersImg: allImageSharp(
      filter: { original: { src: { regex: "/hero-number/" } } }
      sort: { fields: original___src }
    ) {
      edges {
        node {
          id
          fluid(maxWidth: 355, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
