import styled, { css } from "styled-components"

import { media, customMedia } from "./../../common/MediaQueries"
import { InnerContainer } from "./../InnerContainer"

const HeroInnerStyled = styled.div`
  height: 100%;
  @media ${customMedia.maxPhone} {
    display: flex;
    min-height: calc(100 * var(--vh));
  }
  @media ${media.phone} {
    height: 100%;
  }
`

const HeroContentStyled = styled.div`
  max-width: 256px;
  @media ${media.phone} {
    max-width: 450px;
  }
`

const HeroHeadingStyled = styled.div`
  letter-spacing: -1px;
`

const HeroDescriptionStyled = styled.div`
  margin-bottom: 16px;
  margin-top: 38px;

  @media ${media.phone} {
    margin-top: 42px;
    margin-bottom: 42px;
  }
  @media ${media.tablet} {
    margin-top: 40px;
    margin-bottom: 24px;
  }
`

const HeroSubheadingStyled = styled.div`
  margin-top: 24px;

  @media ${media.tablet} {
    margin-top: 22px;
  }
`

const HeroActionStyled = styled.div``

const HeroMediaStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 12px;
  width: 300px;
  height: 185px;
  @media ${customMedia.maxPhoneSmall} {
    display: none;
  }
  @media ${media.phone} {
    width: 475px;
    height: 293px;
    bottom: 18px;
    right: -32px;
  }
  @media ${media.tablet} {
    position: relative;
    align-self: flex-end;
    width: 838px;
    height: 516px;
    bottom: 34px;
    right: -50px;
  }
  @media ${media.desktopXl} {
    position: absolute;
    right: -26px;
  }
`

const HeroInnerContainerStyled = styled(InnerContainer)`
  position: relative;

  @media ${media.phone} {
    height: 100%;
  }

  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
  }
  @media ${media.desktopXl} {
    justify-content: space-between;
    flex-direction: row;
  }
`

const HeroAdditionalStyled = styled.div`
  width: 100%;
  margin-top: 30px;
  @media ${media.phone} {
    margin-top: 54px;
  }
  @media ${media.tablet} {
    padding-bottom: 16px;
  }
`

const HeroStyled = styled.div`
  width: 100%;
  overflow: hidden;

  background-color: ${({ theme }) => theme.colors.black};

  height: 100%;
  min-height: calc(100 * var(--vh));

  @media ${media.phone} {
    height: 690px;
  }

  @media ${media.tablet} {
    min-height: 678px;
    height: auto;
  }
  ${({ isAlt }) =>
    isAlt &&
    css`
      @media ${media.phone} {
        display: flex;
        height: 100%;
      }
      @media ${media.tablet} {
        min-height: auto;
      }
      @media ${media.desktopXl} {
        height: calc(100 * var(--vh));
        overflow-y: auto;
      }
      ${HeroInnerContainerStyled} {
        padding-top: 32px;
        @media ${media.phone} {
          padding-top: 36px;
        }
        @media ${media.tablet} {
          padding-top: 84px;
        }
      }
      ${HeroInnerStyled} {
        @media ${media.phone} {
          height: auto;
          width: 100%;
        }
      }
      ${HeroContentStyled} {
        max-width: 100%;
        margin-bottom: 30px;
        @media ${media.phone} {
          max-width: 538px;
          margin-bottom: 104px;
        }
        @media ${media.tablet} {
          max-width: 544px;
          margin-bottom: 0;
        }
      }
      ${HeroMediaStyled} {
        position: relative;
        right: -20px;
        margin-left: auto;
        @media ${media.phone} {
          right: -50px;
        }
        @media ${media.tablet} {
          margin-left: 0;
        }
        @media ${media.desktopXl} {
          position: absolute;
        }
      }
    `}
`

export {
  HeroStyled,
  HeroInnerStyled,
  HeroContentStyled,
  HeroHeadingStyled,
  HeroSubheadingStyled,
  HeroDescriptionStyled,
  HeroActionStyled,
  HeroMediaStyled,
  HeroInnerContainerStyled,
  HeroAdditionalStyled,
}
