import { keyframes } from "styled-components"

const wave = keyframes`
  0%   {transform: translate(-1px, 2px) rotate(2deg) }
  25%  {transform: translate(-2px, 3px) rotate(-2deg) }
  50%  {transform: translate(-1px, 2px) rotate(2deg) }
  75%  {transform: translate(0px, 3px) rotate(-2deg) }
  100% {transform: translate(-1px, 2px) rotate(2deg) }  
`

const fadeIn = keyframes`
from {opacity:0;}
to   {opacity:1;}
`

export { wave, fadeIn }
