import styled from "styled-components"

import { media } from "./../common/MediaQueries"

const IntroStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  @media ${media.tablet} {
    min-height: calc(100 * var(--vh));
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 100vh;
  }
`

export { IntroStyled }
